@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


.category-card {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  text-align: left;
  /* Align text to the left */
  /* padding: 20px; */
  height: 300px;
  display: flex;
  /* Use flexbox for centering */

}

/* .category-card:hover{
   background-color: #666262;
} */

.category-card h2 {
  color: #181818;
  font-weight: 600;
  font-size: 24px;
  font-family: "Manrope", sans-serif;

}

.category-card .product-image {
  position: relative;
  z-index: 2;
  /* Ensure product image stays on top */
  object-fit: contain;
  /* Maintain aspect ratio */
  margin-bottom: 10px;
  /* Space between image and text */
}

.category-card .image1-1 {
  width: 30rem;
  margin-top: 2rem;
  margin-left: 5rem;

}

.category-card .image1 {

  width: 20rem;
  margin-top: 4rem;
  margin-left: 6rem;

}

.category-card .image2 {
  width: 14rem;
  margin-top: 3.5rem;
  margin-left: 12rem;
  margin-bottom: 0;
  margin-right: 0;
}

.category-card .image3 {
  width: 26.5rem;
  margin-top: 2.5rem;
  margin-bottom: 0;
  margin-left: 13.6rem;
}

.category-card .image4 {
  width: 23rem;
  margin-top: 2.7rem;
  margin-bottom: 0;
  margin-left: 16.7rem;
}



.category-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  /* Padding for the text */
  z-index: 3;
}

.category-overlay h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  z-index: 3;
  text-align: left;
  /* Keep text aligned to the left */
}

/* Gradient backgrounds for each card */
.gradient-bg-1 {
  /* background: url('../Img/Ledback.png') no-repeat center center / cover; */
  background-color: #ffffff;
}

.gradient-bg-2 {
  /* background: url('../Img//projector-back.png') no-repeat center center / cover; */
  background-color: #ffffff;

}

.gradient-bg-3 {
  /* background: url('../Img/accback.png') no-repeat center center / cover; */
  background-color: #ffffff;

}

.gradient-bg-4 {
  /* background: url('../Img//pannal-back.png') no-repeat center center / cover; */
  background-color: #ffffff;

}

.gradient-bg-5 {
  /* background: url('../Img//converter-back.png') no-repeat center center / cover; */
  background-color: #ffffff;

}


/* Apply the hover effect to the image when hovering on the container */
.gradient-bg-1:hover img,
.gradient-bg-2:hover img,
.gradient-bg-3:hover img,
.gradient-bg-4:hover img,
.gradient-bg-5:hover img {
  transform: scale(1.1);
  /* Scale image when hovering on the container */
}

/* Ensure smooth transitions */
.gradient-bg-1 img,
.gradient-bg-2 img,
.gradient-bg-3 img,
.gradient-bg-4 img,
.gradient-bg-5 img {
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  /* Smooth transitions */
}


.product-card-2 img {
  width: 412px;
  height: 273px;
}


@media screen and (max-width: 768px) {
  .category-card .image2 {
    margin-left: 11.5rem; 
  }

  .category-card .image3 {
    margin-left: 3rem;
  }

  .category-card .image4 {
    margin-left: 3rem;
  }
}


@media  screen and (min-width: 769px) and (max-width: 1024px) {
  .category-card .image2 {
    margin-left: 9rem; 
  }

  .category-card .image3 {
    margin-left: 3rem;
  }

  .category-card .image4 {
    margin-left: 3rem;
  }
}

@media  screen and (min-width: 1025px) and (max-width: 1284px), 
screen and (min-resolution: 2dppx) and (max-width: 1284px) {
  .category-card .image2 {
    margin-left: 11rem; 
  }

  .category-card .image3 {
    margin-left: 8rem;
  }

  .category-card .image4 {
    margin-left: 6rem;
  }
}