.video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fullscreen-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video fills the screen */
  }
  