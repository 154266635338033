@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.Navbar-container .dropdown a {
    color: #1D1D1D;
    font-weight: 600;
    margin-right: 1.5rem;
}


.Navbar-container .navbar-brand{
    margin-left: 6rem;
}

.Navbar-container .navbar-nav{
    margin-right: 6rem !important;
}

.category-list li {
    text-transform: capitalize
}

@media only screen and (min-width: 1512px) and (max-width: 1800px) {
   

    .Navbar-container .navbar-brand{
        margin-left: 6.5rem;
    }
    
    .Navbar-container .navbar-nav{
        margin-right: 6.5rem !important;
    }
   
}


.Navbar-container .dropdown a:hover {
    color: rgb(100, 94, 94);
}

.Navbar-container .Partners a:hover {
    color: rgb(100, 94, 94) !important;
}

.Navbar-container .dropdown-item {
    padding: 1rem;
    border-bottom: 0.5px solid #ccc;
    font-weight: 400 !important;
}

.Navbar-container .dropdown-item:last-child {
    border-bottom: none;
}


.carousel {
    z-index: 1;
}

.Navbar-container, .navbar-collapse {
    transition: none !important;
}


.Navbar-container .navbar-nav {
    margin-right: 5rem;
}

.navbar-nav .dropdown-menu {
    width: 250px;
    z-index: 1065;
    position: absolute;
    top: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

/* Default styles for the navbar */
.Navbar-container {
    position: fixed;
    width: 100%;
    z-index: 1030;
    top: 0;
    background-color: #ffffff;
    height: 90px;
}


.navbar-collapse {
    position: relative;
}

.navbar-collapse.show {
    z-index: 1;
}

/* On mobile, make sure the content below is pushed down when navbar expands */
@media (max-width: 768px) {

    .Navbar-container .dropdown a,
    .Partners {
        margin-left: 1.5rem;
        color: #1D1D1D;
    }

    .Navbar-container {
        position: fixed;
        width: 100%;
        z-index: 1030;
    }

    /* When navbar is expanded, make sure it takes up enough space */
    .navbar-collapse {
        background-color: #ffffff;
        height: auto;
        z-index: 1031;
       
    }

    .navbar-collapse.show {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 90px;
        left: 0;
        z-index: 1031;

    } 

}



/* banner control */

.banner-control {
    width: 50px;
    height: 50px;
    background-color: #181818;
    border-radius: 50%;
}

/* .banner-control span{
    font-size: 2px;
} */

@keyframes slideInLeftToRight {
    from {
        opacity: 0;
        transform: translateX(-100%); /* Start slightly below */
    }
    to {
        opacity: 1;
        transform: translateX(0); /* End at original position */
    }
}

.banner-text {
    position: absolute;
    top:30%;
    left: 10%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4.5rem;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    opacity: 0;
}

.banner-text.animateText {
    animation: slideInLeftToRight 0.5s ease-in-out forwards;
    animation-delay: 1s;
}

/* .text-box{
    width: 20% !important;
} */


.carousel-control-prev {
    justify-content: start;
    padding-left: 1.5rem;
}

.carousel-control-next {
    justify-content: end;
    padding-right: 1.5rem;
}

@keyframes slideInRightToLeft {
    from {
        opacity: 0;
        transform: translateX(100%); /* Start slightly below */
    }
    to {
        opacity: 1;
        transform: translateX(-50%, -50%); /* End at original position */
    }
}

.banner-item {
    position: absolute;
    top: 50%;
    left: 75%;
    width: 600px;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.banner-item.animate {
    animation: slideInRightToLeft 0.8s ease-in-out forwards;
    animation-delay: 0.4s;
}


.banner1 {
    background-color: #1565BD;
    /* background-image: url('../Img/NIO 2.png'); */
    background-repeat: no-repeat;
    background-position: start;
    background-size: 50% cover;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: start;
    background-size: 50% cover;
    opacity: 0.5;
    z-index: -1; /* Ensures it stays behind the content */
    transition: background-image 0.3s ease; /* Smooth transitions between slides */
}



@media screen and (max-width: 768px) {

    .banner-control {
        width: 40px;
        height: 40px;
    }

    .banner-control span {
        width: 1rem;
    }

    .carousel-control-prev {

        padding-left: 1rem;
    }

    .carousel-control-next {

        padding-right: 1rem;
    }

    .banner-text {
        font-size: 1.8rem;
         top: 25%;
         left: 15%;
        /* Smaller font for smaller screens */
    }

    .banner-item {
        position: absolute;
        top: 50%;
        /* Adjust to position vertically */
        left: 75%;
        /* Adjust to position horizontally */
        width: 180px;
        transform: translate(-50%, -50%);
        /* Centers the text */

    }

    .pen-item {
        width: 250px;
        top: 55%;
    }


}


/* For tablets and small laptops */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .banner-control {
        width: 35px;
        height: 35px;
    }

    .banner-text {
        font-size: 1.8rem;
    }

    .banner-item {
        top: 50%;
        left: 72%;
        width: 130px;
        transform: translate(-50%, -50%);
    }

    .pen-item {
        width: 220px;
        top: 50%;
    }
}




/* contact offcanvas */
.contact-offcanvas {
    padding: 0.8rem 0.5rem !important;
}

.contact-offcanvas .offcanvas-body {
    padding-top: 0 !important;
    scrollbar-width: none !important;
}

.contact-offcanvas .contact label {
    font-size: 12px;
    font-weight: 400;
    font-family: "Manrope", sans-serif;
    margin-bottom: 3px;
}

.contact-offcanvas .contact input,
.contact-offcanvas .contact textarea {
    background-color: #E8F0F9;
    padding: 0.7rem 0.5rem;
}

.contact-offcanvas .contact .form-control::placeholder {
    color: #8E8E8E;
}

@media screen and (max-width: 768px) {
    .contact-offcanvas{
        width: 100% !important;
    }
}