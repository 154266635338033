/* TestimonialCarousel.css */
.testimonial-box {
    background-color: #fff;
    border-radius: 10px;
    max-width: 900px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .quote-icon {
    color: #007bff;
    font-size: 48px;
  }
  
  .testimonial-text {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
  }
  
  .testimonial-author {
    font-weight: 500;
  }
  
  .custom-dots .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .custom-dots .dot.active {
    background-color: #007bff;
  }
  
  .custom-dots .dot:hover {
    background-color: #0056b3;
  }
  